





















































import { Component, PropSync, Vue } from 'vue-property-decorator';

import InvestorGoalLinkedSuccessfullyViewModel from
  '@/vue-app/view-models/components/goals-dashboard/create-goal/investor-goal-linked-successfully-view-model';

@Component({ name: 'InvestorGoalLinkedSuccessfully' })
export default class InvestorGoalLinkedSuccessfully extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  synced_is_open!: boolean;

  view_model = Vue.observable(
    new InvestorGoalLinkedSuccessfullyViewModel(),
  );

  created() {
    this.view_model.initialize();
  }
}
